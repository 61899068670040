import React, { useState, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import Logos from "../assets/images/Group 138.svg";
import { useLocation, useNavigate } from "react-router-dom";
import useLocalStorage from "use-local-storage";
// import { useSelector, useDispatch } from 'react-redux'

// import { addUser } from "../features/user/userSlice";
import { getLastSigninDate, signInAPI } from "../apis/api";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import { Hourglass } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const Signin = () => {
  const { t } = useTranslation();
  const [spinner, setSpinner] = useState(false);
  const preference = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDark, setIsDark] = useLocalStorage("isDark", preference);
  //   const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  // const [error, setError] = useState("");
  // const [message, setMessage] = useState("");
  const location = useLocation()
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const myInlineStyles = {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    color: "red",
    fontFamily: "Arial",
  };
  useEffect(() => {
    setEmailError("");
    setPasswordError("");
    const loginData = secureLocalStorage.getItem("loginData");

    if (loginData) {
      navigate("/Dashboard", {state: {forPlan: location?.state?.forPlan}});
    }
  }, []);
  function isValidEmail(email) {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  }
  useEffect(() => {}, []);
  useEffect(() => {
    setEmailError(
      email == ""
        ? "Required*"
        : isValidEmail(email)
        ? { data: "", status: true }
        : "email invalid format"
    );
    setPasswordError(password ? { data: "", status: true } : "Required*");
  }, [email, password]);

  const handleSignIn = async () => {
    

    signInAPI(email, password)
      .then((response) => {
        setSpinner(false);
        // console.log("response", response);
        if (response) {
          secureLocalStorage.setItem("loginData", {
            token: response.data.token,
            timestamp: response.data.token_timestamp,
            email: email,
          });
          navigate("/Dashboard", {state: {forPlan: location?.state?.forPlan}});
          // alert("login succefull!!!");
          // notify(t("Login_Successful"), "success");
        }
      })
      .catch((err) => {
        notify(err?.data?.message, "error");
        console.log(err);
        setSpinner(false);
        if (err?.data?.message == "Email not verified"){
          navigate("/accountrecovery",{
            state: {
              email: email,
              
            },
          })
        }
        // alert(err.data.error);
        // console.log("err", err.data.error);
      });
  };

  const handleCheckLastLogin = () =>{
    if (email === "" || password === "") {
      notify("Please fill all the fields", "error");
      return;
    }
    setSpinner(true);

    getLastSigninDate(email)
    .then((response)=>{
      if (!response.data.message){

        console.log(response);
        let lastLogin = new Date(response.data.last_login);
        lastLogin.setDate(lastLogin.getDate() + 10);
        console.log(lastLogin.getTime());
        console.log(Date.now());
        let testDate = new Date();
        testDate.setDate(testDate.getDate() + 12)
        if (lastLogin.getTime() > Date.now()){
        // if (lastLogin.getTime() > testDate.getTime()){
          console.log("Pop not show");
          handleSignIn();
        }
        else{
          console.log("Pop up show");
          setShowFirstModal(true);
        }
      }
      else if (response.data.message == "User found but never logged in."){
        setShowFirstModal(true);

      }
    }
  ).catch((err)=>{
   if ( err.data.message == "User not found!" || err.data.message == "No login information available."){
    handleSignIn();
   }
   else{
    notify(err?.data?.message, "error");
   }
  })
  }
  const notify = (message, type) => {
    toast[type](message, {
      position: "top-right",
      autoClose: 2000,
    });
  };

  // Terms and conditions agreement Modals

  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [showThirdModal, setShowThirdModal] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const modalRef = useRef(null);

  const handleCloseFirstModal = () => {
    if (termsChecked && privacyChecked) {
      setShowFirstModal(false);
      // setShowThirdModal(true);
      setShowSecondModal(true);

    } else {
      toast.error("Accept Terms and Privacy policy", {
        toastId: "Agree error",
      });
    }
  };

  const handleCloseSecondModal = () => {
    setShowSecondModal(false);
    setShowThirdModal(true);
  };

  const handleCloseThirdModal = () => {
    setShowThirdModal(false);
    setShowSecondModal(true);
  };

  const handleAcceptCookies = () => {
    setCookiesAccepted(true);
    setShowSecondModal(false);
    handleSignIn();
  };

  const handleTermsChange = () => {
    setTermsChecked(!termsChecked);
  };

  const handlePrivacyChange = () => {
    setPrivacyChecked(!privacyChecked);
  };

  useEffect(() => {
    const loginData = secureLocalStorage.getItem("loginData");
    if (loginData) {
      navigate("/dashboard");
    }
    // setShowFirstModal(true);
  }, []);
  useEffect(() => {
    if (location?.state?.showPasswordToast) {
      notify("Password has been reset. Please make sure to click the update password.", "info")
    }
    // setShowFirstModal(true);
  }, []);

  return (
    <>
      {" "}
      <div className="background-account" data-theme={isDark ? "dark" : ""}>
        <div className="signinPage" data-theme={isDark ? "dark" : ""}>
          <div className="signin-content" data-theme={isDark ? "dark" : ""}>
            <div className="signup-logo" data-theme={isDark ? "dark" : ""}>
              <img
                src={Logos}
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/")}
                alt="signuplogo"
                className="custom"
              />
            </div>
            <div className="signup-card" data-theme={isDark ? "dark" : ""}>
              <div
                className="signupcard-content"
                data-theme={isDark ? "dark" : ""}
              >
                <div className="main-card" data-theme={isDark ? "dark" : ""}>
                  <div className="cardtitle" data-theme={isDark ? "dark" : ""}>
                    <div
                      className="leftside"
                      onClick={() => {
                        
                      }}
                      data-theme={isDark ? "dark" : ""}
                    >
                      <h6 data-theme={isDark ? "dark" : ""}>{t("LogIn")}</h6>
                    </div>
                    {/* <div
                    className="rightside"
                    onClick={() => {
                      navigate("/signup");
                    }}
                  >
                    <h6>
                      No Account ? <br /> <span>Sign up</span>
                    </h6>
                  </div> */}
                  </div>

                  <div className="card-field" data-theme={isDark ? "dark" : ""}>
                    <div
                      className="firstfield"
                      data-theme={isDark ? "dark" : ""}
                    >
                      <div className="label" data-theme={isDark ? "dark" : ""}>
                        <label htmlFor="" data-theme={isDark ? "dark" : ""}>
                          {t("EnterYourEmailAddress")}
                        </label>{" "}
                        <span
                          style={{
                            color: "red",
                            fontSize: "14px",
                            fontFamily: "Arial",
                          }}
                          className="span"
                          data-theme={isDark ? "dark" : ""}
                        >
                          {emailError == "Required*"
                            ? "Required*"
                            : emailError == "email invalid format"
                            ? "email invalid format"
                            : emailError.data}
                        </span>
                      </div>
                      <div
                        className="inputfield"
                        data-theme={isDark ? "dark" : ""}
                      >
                        <input
                          type="email"
                          placeholder="Email address"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailError(""); // Clear error when user starts typing
                          }}
                          data-theme={isDark ? "dark" : ""}
                        />
                      </div>
                      {/* <span style={{ color: "red" }} className="span">
                      {emailError}
                    </span> */}
                    </div>
                    <div
                      className="firstfield"
                      data-theme={isDark ? "dark" : ""}
                    >
                      <div className="label" data-theme={isDark ? "dark" : ""}>
                        <label htmlFor="" data-theme={isDark ? "dark" : ""}>
                          {t("EnterYourPassword")}
                        </label>{" "}
                        <span style={myInlineStyles}>
                          {" "}
                          {passwordError == "Required*"
                            ? "Required*"
                            : passwordError.data}
                        </span>
                      </div>
                      <div
                        className="inputfield"
                        data-theme={isDark ? "dark" : ""}
                      >
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setPasswordError(""); // Clear error when user starts typing
                          }}
                          data-theme={isDark ? "dark" : ""}
                        />
                        <div
                          className="eye-icon"
                          onClick={togglePasswordVisibility}
                          data-theme={isDark ? "dark" : ""}
                        >
                          {showPassword ? (
                            <Icon
                              icon="octicon:eye-24"
                              color="gray"
                              width="18"
                              height="18"
                            />
                          ) : (
                            <Icon
                              icon="tabler:eye-off"
                              color="gray"
                              width="18"
                              height="18"
                            />
                          )}
                        </div>
                      </div>
                      {/* <span style={myInlineStyles}>{passwordError}</span> */}
                    </div>
                  </div>
                  <div
                    className="forgetPassword"
                    data-theme={isDark ? "dark" : ""}
                  >
                    <span
                      onClick={() => {
                        navigate("/email");
                      }}
                      data-theme={isDark ? "dark" : ""}
                    >
                      {t("ForgotPassword")}
                    </span>
                  </div>
                  <div
                    className="annotherAccount"
                    data-theme={isDark ? "dark" : ""}
                  >
                    <p>
                      {" "}
                      {t("DontHaveAccount")}
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/signup");
                        }}
                      >
                        &nbsp;{t("signUp")}
                      </span>
                    </p>
                  </div>
                  <div className="line" data-theme={isDark ? "dark" : ""}>
                    <div className="leftline" data-theme={isDark ? "dark" : ""}>
                      <hr />
                    </div>
                    <div
                      className="middletext"
                      data-theme={isDark ? "dark" : ""}
                    >
                      <span>{t("or")}</span>
                    </div>
                    <div className="leftline" data-theme={isDark ? "dark" : ""}>
                      <hr />
                    </div>
                  </div>
                  <div
                    className="cardSocialmedia"
                    data-theme={isDark ? "dark" : ""}
                  >
                    <div
                      className="googlebtn"
                      data-theme={isDark ? "dark" : ""}
                    >
                      <button data-theme={isDark ? "dark" : ""}>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          viewBox="0 0 26 26"
                          fill="none"
                        >
                          <path
                            d="M24.3761 13.2525C24.3761 12.3173 24.2987 11.6348 24.1311 10.927H13.2333V15.1483H19.63C19.5011 16.1974 18.8047 17.7773 17.2571 18.8389L17.2354 18.9802L20.681 21.5962L20.9198 21.6195C23.1122 19.6352 24.3761 16.7156 24.3761 13.2525Z"
                            fill="#4285F4"
                          />
                          <path
                            d="M13.2326 24.3749C16.3664 24.3749 18.9973 23.3637 20.919 21.6196L17.2563 18.8389C16.2762 19.5088 14.9607 19.9764 13.2326 19.9764C10.1631 19.9764 7.55802 17.9922 6.62936 15.2495L6.49324 15.2608L2.91038 17.9782L2.86353 18.1059C4.77223 21.8217 8.69286 24.3749 13.2326 24.3749Z"
                            fill="#34A853"
                          />
                          <path
                            d="M6.63007 15.2496C6.38504 14.5418 6.24323 13.7834 6.24323 12.9999C6.24323 12.2162 6.38504 11.4579 6.61718 10.7501L6.61069 10.5994L2.98292 7.83838L2.86422 7.89371C2.07755 9.43567 1.62616 11.1672 1.62616 12.9999C1.62616 14.8325 2.07755 16.564 2.86422 18.1059L6.63007 15.2496Z"
                            fill="#FBBC05"
                          />
                          <path
                            d="M13.2326 6.0233C15.4122 6.0233 16.8824 6.94594 17.7207 7.71696L20.9965 4.5825C18.9846 2.74987 16.3665 1.625 13.2326 1.625C8.69289 1.625 4.77224 4.17804 2.86353 7.89384L6.61649 10.7503C7.55805 8.00763 10.1632 6.0233 13.2326 6.0233Z"
                            fill="#EB4335"
                          />
                        </svg>{" "}
                        {t("LogInWithGoogle")}
                      </button>
                    </div>
                    <div
                      className="facebook-btn"
                      data-theme={isDark ? "dark" : ""}
                    >
                      <button data-theme={isDark ? "dark" : ""}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="29"
                          height="29"
                          viewBox="0 0 29 29"
                          data-theme={isDark ? "dark" : ""}
                          fill={isDark ? "#000000" : "none"}
                        >
                          <ellipse
                            cx="14.5"
                            cy="13.3401"
                            rx="12.6875"
                            ry="12.6875"
                            fill="url(#paint0_linear_860_11143)"
                          />
                          <path
                            d="M19.2249 18.3802L19.7885 14.7992H16.2629V12.4763C16.2629 11.4964 16.7544 10.5407 18.3336 10.5407H19.9375V7.49196C19.9375 7.49196 18.4825 7.25 17.0921 7.25C14.1872 7.25 12.2903 8.96548 12.2903 12.0698V14.7992H9.0625V18.3802H12.2903V27.0375C12.9383 27.1367 13.6012 27.1875 14.2766 27.1875C14.9519 27.1875 15.6148 27.1367 16.2629 27.0375V18.3802H19.2249Z"
                            fill={isDark ? "#000000" : "#F7F7F7"}
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_860_11143"
                              x1="14.5"
                              y1="0.652588"
                              x2="14.5"
                              y2="25.9523"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#18ACFE" />
                              <stop offset="1" stopColor="#0163E0" />
                            </linearGradient>
                          </defs>
                        </svg>
                        {t("LogInWithFacebook")}
                      </button>
                    </div>
                  </div>
                  <div
                    className="card-bottom"
                    data-theme={isDark ? "dark" : ""}
                  >
                    <div
                      className="signin-btn"
                      data-theme={isDark ? "dark" : ""}
                    >
                      <button
                        // onClick={() => {
                        //   navigate("/plan");
                        // }}
                        // onClick={handleSignIn}
                        onClick={handleCheckLastLogin}
                        data-theme={isDark ? "dark" : ""}
                      >
                        {t("LogIn")}
                        {spinner && (
                          <span>
                            {" "}
                            {/* <Spinner
                              animation="border"
                              variant="primary"
                              size="sm"
                            /> */}
                            <Hourglass
                              visible={true}
                              height="15"
                              width="15"
                              ariaLabel="hourglass-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              colors={
                                isDark ? ["#000", "#000"] : ["#fff", "#fff"]
                              }
                              data-theme={isDark ? "light" : "dark"}
                            />
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modals */}

        {/* First Modal */}
        <Modal
          show={showFirstModal}
          onHide={handleCloseFirstModal}
          className="termsmodal"
          size="xl"
          centered
          ref={modalRef}
        >
          <Modal.Body className="termsmodal-body">
            <div className="terms-modal">
              <div className="terms-header">
                <span>{t("termsOfService")}</span>
              </div>
              <div className="middle-body h-auto">
                <div className="middle-top">
                  <p>{t("LawvocateAIDisclaimer")}</p>
                  <p>
                    {t("expressAgreementRequirement")}
                    <span>{t("PrivacyPolicy")}</span>
                    {t("and")}
                    <span>{t("termsOfUse")}</span>.{t("confirmUnderstanding")}
                  </p>
                </div>
              </div>
              <div className="checkbox-termsmodal">
                <div className="form-check formcheck-parent">
                  <input
                    className="form-check-input formcheckinput"
                    type="checkbox"
                    id="tos"
                    checked={termsChecked}
                    onChange={handleTermsChange}
                  />
                  <label
                    className="form-check-label formchecklabel"
                    htmlFor="tos"
                  >
                    <strong>{t("termsOfService")}</strong>
                  </label>
                  <a href="/termsofservice">(See Terms and conditions)</a>
                </div>
                <div className="form-check formcheck-parent">
                  <input
                    className="form-check-input formcheckinput"
                    type="checkbox"
                    id="pp"
                    checked={privacyChecked}
                    onChange={handlePrivacyChange}
                  />
                  <label
                    className="form-check-label formchecklabel"
                    htmlFor="pp"
                  >
                    <strong> {t("PrivacyPolicy")}</strong>
                  </label>
                  <a href="/privacy-policy">(See Privacy Policy)</a>
                </div>
              </div>

              <div className="term-botton">
                <button
                  className="agree"
                  onClick={handleCloseFirstModal}
                  // disabled={!(termsChecked && privacyChecked)}
                >
                  Agree
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Second Modal */}
        <Modal
          show={showSecondModal}
          onHide={handleCloseSecondModal}
          className="termsmodal cookies-modal"
          size="lg"
          centered
          backdrop="static" // Prevent closing when clicking outside
          keyboard={false} // Prevent closing with the escape key
          ref={modalRef}
        >
          {/* Second Modal content */}
          <Modal.Body className="termsmodal-body cookies-modal-body">
            <div className="terms-modal">
              <div className="middle-body h-auto">
                <p className="text-center">{t("cookiesConsentMessage")}</p>
              </div>
              <div className="term-botton">
                <Button
                  className="accept-cookies"
                  onClick={handleAcceptCookies}
                >
                  {t("acceptCookies")}
                </Button>
                {/* <Button className="change-settings" onClick={handleCloseSecondModal}>
                {t("changeSettings")}
              </Button> */}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Third Modal */}
        <Modal
          show={showThirdModal}
          onHide={handleCloseThirdModal}
          className="termsmodal cookies-modal"
          size="lg"
          centered
          backdrop="static" // Prevent closing when clicking outside
          keyboard={false} // Prevent closing with the escape key
          ref={modalRef}
        >
          {/* Third Modal content */}
          <Modal.Body className="termsmodal-body cookies-modal-body">
            <div className="terms-modal">
              <div className="middle-body h-auto">
                <p className="text-center">
                  Are you sure, you want to accept our&nbsp;
                  <a href="/termsofservice">Terms and Conditions?</a>
                </p>
              </div>
              <div className="term-botton">
                <Button
                  className="accept-cookies"
                  onClick={handleCloseThirdModal}
                >
                  Yes
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Signin;
