import React, { useState } from "react";
import Cardlogo from "../../../../assets/images/smalltag.png";
import Cardblacklogo from "../../../../assets/images/cardblacklogo.png";
import Tick from "../../../../assets/images/tick.svg";
import BlackTick from "../../../../assets/images/blacktick.svg";
import useLocalStorage from "use-local-storage";
import { useTranslation } from "react-i18next";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

const Subscriptionplan2 = () => {
  const { t } = useTranslation();
  const [clientSecret, setClientSecret] = useState(null);
  const stripe = useStripe();
  const stripePromise = loadStripe(
    "pk_test_51OfEttL6Aona5j88W56lRo0uSSmUoq2Ux9o7SDqB2XT2yMzb7SLUP1AtZshzTl2lrWVOsn63MBobVX41JWyU8Zwf00tPLu221Y"
  );
  const fetchClientSecret = async (plan, amount) => {
    try {
      const loginData = secureLocalStorage.getItem("loginData");
      const email = loginData.email;

      const response = await axios.post(
        process.env.REACT_APP_Render_Endpoint + "/create_checkout_session",
        { amount: amount, planName: plan, email: email },
        {
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data.sessionId);
      setClientSecret(response.data);

      // When the customer clicks on the button, redirect them to Checkout.
      const result = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId,
      });
      if (result.error) {
        toast.error(result.error.message, "error");
      }
      // alert("Domain name is available");
    } catch (error) {
      console.error("Error fetching client secret:", error);
    }
  };

  const preference = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDark, setIsDark] = useLocalStorage("isDark", preference);
  return (
    <div>
      <div className="dashboardplan">
        <section className="plans-sections plan-2 mt-5">
          <h3 className=" ">{t("annual2monthsFree")} </h3>
          <div className="plans-cards">
            <div className="ist" data-theme={isDark ? "dark" : ""}>
              <p className="plan-type" data-theme={isDark ? "dark" : ""}>
                {t("plan")}
              </p>
              <div className="price" data-theme={isDark ? "dark" : ""}>
                <h3>$10</h3>
                <p>{t("Month")}</p>
              </div>
              <button
                className="started"
                data-theme={isDark ? "dark" : ""}
                onClick={() => {
                  fetchClientSecret(t("plan"), "10");
                }}
              >
                {t("action")}
              </button>
              <div className="lists">
                <div className="list" data-theme={isDark ? "dark" : ""}>
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("the_rest_listed")}</p>
                </div>
                <div className="list" data-theme={isDark ? "dark" : ""}>
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("Email_support")}</p>
                </div>
                <div className="list" data-theme={isDark ? "dark" : ""}>
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("Monthly_legal_advice")}</p>
                </div>
                {/* <div className="list" data-theme={isDark ? "dark" : ""}>
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("Priority_access")}</p>
                </div>
                <div className="list" data-theme={isDark ? "dark" : ""}>
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("Free_monthly")}</p>
                </div> */}
                {/* <div className="list" data-theme={isDark ? "dark" : ""}>
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("growth")}</p>
                </div>
                <div className="list" data-theme={isDark ? "dark" : ""}>
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("review")}</p>
                </div>
                <div className="list" data-theme={isDark ? "dark" : ""}>
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("business")}</p>
                </div>
                <div className="list" data-theme={isDark ? "dark" : ""}>
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("marketing")}</p>
                </div>
                <div className="list" data-theme={isDark ? "dark" : ""}>
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("planning")}</p>
                </div> */}
              </div>
            </div>
            <div className="second" data-theme={isDark ? "dark" : ""}>
              {isDark ? (
                <img
                  // src="/assets/images/popular.svg"
                  src={Cardblacklogo}
                  alt="..."
                  className="popular"
                />
              ) : (
                <div className="div">{/* <span>2 month free</span> */}</div>
              )}

              <p className="plan-type">{t("action")}</p>
              <div className="price" data-theme={isDark ? "dark" : ""}>
                <h3>$25</h3>
                <p>{t("Month")}</p>
              </div>
              <button
                className="started"
                data-theme={isDark ? "dark" : ""}
                onClick={() => {
                  fetchClientSecret(t("action"), 38);
                }}
              >
                {t("action")}
              </button>
              <div className="lists">
                <div className="list" data-theme={isDark ? "dark" : ""}>
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("the_rest_listed")}</p>
                </div>
                <div className="list" data-theme={isDark ? "dark" : ""}>
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("Unlimited_AI")}</p>
                </div>
                <div className="list" data-theme={isDark ? "dark" : ""}>
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("Live_chat_support")}</p>
                </div>
                <div className="list" data-theme={isDark ? "dark" : ""}>
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("Priority_access")}</p>
                </div>
                <div className="list" data-theme={isDark ? "dark" : ""}>
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("Free_monthly")}</p>
                </div>
                {/* <div className="list" data-theme={isDark ? "dark" : ""}>
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("growth")}</p>
                </div>
                <div className="list" data-theme={isDark ? "dark" : ""}>
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("review")}</p>
                </div>
                <div className="list" data-theme={isDark ? "dark" : ""}>
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("business")}</p>
                </div>
                <div className="list" data-theme={isDark ? "dark" : ""}>
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("marketing")}</p>
                </div>
                <div className="list" data-theme={isDark ? "dark" : ""}>
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("planning")}</p>
                </div> */}
              </div>
            </div>
            <div className="third" data-theme={isDark ? "dark" : ""}>
              <p className="plan-type">{t("premium")}</p>
              <div className="price" data-theme={isDark ? "dark" : ""}>
                <h3>$50</h3>
                <p>{t("Month")}</p>
              </div>
              <button
                className="started"
                data-theme={isDark ? "dark" : ""}
                onClick={() => {
                  fetchClientSecret(t("premium"), "65");
                }}
              >
                {t("action")}
              </button>
              <div className="lists">
                {/* <div className="list" data-theme={isDark ? "dark" : ""}>
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("10days")}</p>
                </div> */}
                <div className="list" data-theme={isDark ? "dark" : ""}>
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("unlimitedAIlegalAdvice")}</p>
                </div>
                <div className="list">
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("liveChatSupport")}</p>
                </div>
                <div className="list">
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("priorityAccessToAttorneys")}</p>
                </div>
                <div className="list">
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("freeMonthlyLegalConsultation")}</p>
                </div>
                <div className="list">
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("the_rest_listed")}</p>
                </div>
                {/*  <div className="list">
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("review")}</p>
                </div>
                <div className="list">
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("business")}</p>
                </div>
                <div className="list">
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("marketing")}</p>
                </div>
                <div className="list">
                  {isDark ? (
                    <img src={BlackTick} alt="Black Tick" />
                  ) : (
                    <img src={Tick} alt="Tick" />
                  )}
                  <p>{t("planning")}</p>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Subscriptionplan2;
